<template>
  <b-card
    v-if="data"
    no-body
  >
    <b-card-header>
      <b-card-title class="ml-25">
        Lịch sử hoạt động
      </b-card-title>
    </b-card-header>

    <!-- timeline -->
    <b-card-body class="card-body-time-line">
      <app-timeline>
        <app-timeline-item
          v-for="(timeLine) in data"
          :user="timeLine.user"
          variant="success"
          :subtitle="timeLine.note"
          :time="timeLine.created"
        />
      </app-timeline>
      <!--/ timeline -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BImg, BMedia, BMediaBody, BMediaAside, BAvatar, BAvatarGroup, VBTooltip,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
/* eslint-disable global-require */
export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardHeader,
    BCardTitle,
    AppTimeline,
    AppTimelineItem,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      // type: Object,
      default: () => {},
    },
  },
}
</script>
<style lang="scss">
.card-body-time-line{
  height: 290px;
  overflow-y: scroll;
}
</style>
