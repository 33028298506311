<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col md="3">
        <statistic-card-vertical
          color="success"
          icon="UsersIcon"
          :statistic="dashboards.statistic ? dashboards.statistic.students : ''"
          statistic-title="Học viên"
        />
      </b-col>
      <b-col md="3">
        <statistic-card-vertical
          color="warning"
          icon="ArchiveIcon"
          :statistic="dashboards.statistic ? dashboards.statistic.courses : ''"
          statistic-title="Khóa học"
        />
      </b-col>
      <b-col md="3">
        <statistic-card-vertical
          color="info"
          icon="BookIcon"
          :statistic="dashboards.statistic ? dashboards.statistic.exams : ''"
          statistic-title="Bài thi"
        />
      </b-col>
      <b-col md="3">
        <statistic-card-vertical
          color="secondary"
          icon="AwardIcon"
          :statistic="dashboards.statistic ? dashboards.statistic.exercises : ''"
          statistic-title="Bài tập"
        />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col md="8">
        <b-row>
          <b-col md="12">
            <b-card>
              <b-card-title class="ml-25">
                Top khóa học
              </b-card-title>
              <b-table
                responsive="sm"
                :items="dashboards.topCourses"
              />
            </b-card>
          </b-col>
          <b-col md="12">
            <b-card>
              <b-card-title class="ml-25">
                Top học viên
              </b-card-title>
              <b-table
                responsive="sm"
                :items="dashboards.topStudents"
              />
            </b-card>
          </b-col>
        </b-row>
      </b-col>
      <b-col md="4">
        <analytics-timeline :data="timeline" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BTable, BCard, BCardHeader, BCardTitle,
} from 'bootstrap-vue'

import CardStatisticOrderChart from '@/views/card/card-statistic/CardStatisticOrderChart'
import CardStatisticProfitChart from '@/views/card/card-statistic/CardStatisticProfitChart'
import CardStatisticsGroup from '@/views/card/card-statistic/CardStatisticsGroup.vue'
import AnalyticsTimeline from '@/views/dashboard/analytics/AnalyticsTimeline'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BCardTitle,
    BCardHeader,
    BCard,
    CardStatisticOrderChart,
    CardStatisticProfitChart,
    CardStatisticsGroup,
    AnalyticsTimeline,
    StatisticCardVertical,
  },
  data() {
    return {
      data: {},
      dashboards: [],
      timeline: [],
    }
  },
  created() {
    this.loadDashBoards()
  },
  methods: {
    loadDashBoards() {
      this.$http.get('/dashboards')
        .then(res => {
          this.dashboards = res.data
          this.timeline = this.dashboards.userActivity.data
        }).catch()
        .finally(() => {
        })
    },
  },
}
</script>
